import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// @mui
import {
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  TableRow,
  MenuItem,
  Container,
  TableBody,
  TableCell,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Chip,
  Box
} from '@mui/material';
import json from '../assets/json.png'
// @mui-icons
import { Cancel, CheckCircle, InfoOutlined, MoreVert, ContentCopy, ReportProblem, WarningOutlined } from '@mui/icons-material';
// sections
import { OrgListHead, OrgListToolbar } from '../sections/orgs';
// API Calls
import { listAllOrgDisplays, listAllOrgs } from "../api/orgs";
import { listAllOrgSessions } from "../api/sessions"
import { listAllOrgUsers } from "../api/users";
// Utils
import { handleShowJson } from '../utils/jsonFunction';
import { convertToNonMilitaryTime, descendingComparator, formatDate, applySortFilter, getComparator, calculateExpiration } from '../utils/formatFunctions';
import { handleCopyClick } from '../utils/copyFunction';
import { productIconMap } from '../utils/mapFunctions';
import { CustomTooltip } from '../utils/customStyles';
import { validateOrganizationObject } from '../utils/validateFunctions';
// ----------------------------------------------------------------------

const tableHead = [
  { id: 'organization', label: 'Organization', alignRight: false },
  { id: 'recentSession', label: 'Recent Session', alignRight: false },
  { id: 'access', label: 'Access', alignRight: false },
  { id: 'orgStatus', label: 'Org Status', alignRight: false },
  { id: 'expired', label: 'Expired', alignRight: false },
  { id: 'manuallyExpired', label: 'Manually Expired', alignRight: false },
  { id: 'menu', label: '', alignRight: false },
];

// ----------------------------------------------------------------------

export default function Organizations () {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(100);

  const [orgData, setOrgData] = useState(null)

  const [orgSessions, setOrgSessions] = useState(null)

  const [orgDisplays, setOrgDisplays] = useState([])

  const [users, setUsers] = useState(null)

  const [expiredOrgs, setExpiredOrgs] = useState(false)

  useEffect(()=> {

      const fetchOrgData = async() => {
          try {
              const data = await listAllOrgs()
              setOrgData(data.data)
          } catch(err) {
              console.log(err)
          }
      }

      const fetchSessionData = async() => {
        try {
            const data = await listAllOrgSessions()
            setOrgSessions(data.data)
        } catch(err) {
            console.log(err)
        }
      }

      const fetchUsers = async() => {
        try {
            const data = await listAllOrgUsers()
            setUsers(data.data)
        } catch(err) {
            console.log(err)
        }
      }


      const fetchOrgDisplays = async() => {
        try {
            const data = await listAllOrgDisplays()
            setOrgDisplays(data.data)
        } catch(err) {
            console.log(err)
        }
      }
 
      fetchOrgData()
      fetchSessionData()
      fetchUsers()
      fetchOrgDisplays()
  }, [])

  const filterSessionsByOrg = (orgId) => {
    if (orgSessions) {
      const filteredSessions = orgSessions.filter((session) => session.org_id === orgId);
      // Sort filtered sessions by date from newest to oldest
      filteredSessions.sort((a, b) => new Date(b.date) - new Date(a.date));
      return filteredSessions;
    }
  }

  const editor = localStorage.getItem("user_type") === "editor"

  // find the most recent session for an organization
  const findMostRecentSession = (orgId, sessions) => {
    const orgSessions = sessions.filter(session => session.org_id === orgId);
    if (orgSessions.length === 0) return null;
    return orgSessions.reduce((prev, current) => {
      return (new Date(current.date) > new Date(prev.date)) ? current : prev;
    });
  };

  // sort organizations by the most recent session
  const sortOrganizationsByRecentSession = (organizations, sessions) => {
      return organizations.sort((org1, org2) => {
        const recentSession1 = findMostRecentSession(org1.organization_id, sessions);
        const recentSession2 = findMostRecentSession(org2.organization_id, sessions);
        if (!recentSession1) return 1;
        if (!recentSession2) return -1;
        return new Date(recentSession2.date) - new Date(recentSession1.date);
      });
  };

  const usersInOrg = (orgId) => {
    if (users) {
      const filteredUsers = users.filter((user) => user.organization_id === orgId)
      return filteredUsers
    }
  }

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleExpirationSwitch = (e)=> {
    setExpiredOrgs(e.target.checked)
  }

  const handleShowJson = (data) => {
    const jsonContent = JSON.stringify(data, null, 2);
    const newWindow = window.open('', '_blank');
    newWindow.document.write(`<pre>${jsonContent}</pre>`);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  if (orgData && users && orgSessions) {
  
    const data = expiredOrgs ? orgData.filter(obj => obj.expired === true) : orgData.filter(obj => obj.expired === false)

    console.log(data)

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

    const filteredOrgs = applySortFilter(data, getComparator(order, orderBy), filterName);
  
    const isNotFound = !filteredOrgs.length && !!filterName;

    const sortedOrganizations = sortOrganizationsByRecentSession(filteredOrgs, orgSessions);
    
  
    return (
      <>

        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              All Organizations
            </Typography>

            <Button variant="contained" href='/dashboard/createorganization' disabled={!editor}>
              Create New Org
            </Button>
          </Stack>

          <OrgListToolbar 
            filterName={filterName} 
            onFilterName={handleFilterByName} 
            expiredOrgs={expiredOrgs}
            handleExpirationSwitch={handleExpirationSwitch}
          />

          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <OrgListHead
                order={order}
                orderBy={orderBy}
                headLabel={tableHead}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {sortedOrganizations.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  const { id, s3_logo_url, name, notes, organization_id, admin_count, user_count, date_created, date_duration, download_analyzed, download_raw, token_api, expired, download_metadata } = row;
                  const userData = usersInOrg(organization_id)
                  const mostRecentSession = findMostRecentSession(organization_id, orgSessions)
                  const sessionsDoneByOrg = filterSessionsByOrg(organization_id)
                  const remainingMembershipTime = calculateExpiration(date_created, date_duration);
                  const orgDisplay = orgDisplays.find((org)=> org.pk_id === organization_id)
                  const productIds = orgDisplay.product_list
                  const exportKeys = orgDisplay.export_keys
                  const istokenApiAnArray = token_api !== null
                  const validationResult = validateOrganizationObject(row);
                  console.log(row, validationResult)


                  let advanced = false;

                  if ("overhand_throw_v3a" in exportKeys) {
                    exportKeys["overhand_throw_v3a"].some(exportKey => {
                      if (exportKey.metric_id === "intensity_zone_ai") {
                        advanced = true;
                        return true;
                      }
                      return false;
                    });
                  }

                  
                  return (
                    <TableRow key={id} tabIndex={-1} sx={{ backgroundColor: validationResult.valid === false ? "warning.main" : "transparent"}}>

                      <TableCell component="th" scope="row">
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Link 
                            to={editor ? `/dashboard/org/${name}` : '/dashboard/orgs'}
                            style={{textDecoration: "none", color: "black"}}
                            state= {{ 
                              orgName: name,
                              orgId: organization_id,
                              userData: userData,
                              orgSessions: sessionsDoneByOrg,
                              isAdvanced: advanced,
                            }}
                          >
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <CustomTooltip title={
                                <>
                                  <Typography>Session Count: {sessionsDoneByOrg.length}</Typography>
                                  <Typography>User Count: {user_count}</Typography>
                                  <Typography>Admin Count: {admin_count}</Typography>
                                </>
                                } 
                                placement="top"
                                arrow
                              >
                              <Avatar 
                                alt={name} 
                                src={s3_logo_url} 
                                sx={{ border: 1, borderColor: "black"}}
                              />
                              </CustomTooltip>
                              <Stack>

                              <Typography noWrap variant='caption'>
                                {name}
                              </Typography>

                              <Typography sx={{ fontStyle: "bold", fontSize: ".7rem"}}>Created on {formatDate(date_created)}</Typography>
                              </Stack>
                            </Stack>
                          </Link>

                          <IconButton>
                            <Box 
                              onClick={() => handleShowJson(row)}
                              component="img"
                              sx={{ width: 30, height: 30 }}
                              src={json}
                            />
                          </IconButton>

                          <CustomTooltip title={notes} placement="top" arrow>
                            <InfoOutlined/>
                          </CustomTooltip>

                          { validationResult.valid === false &&
                            <CustomTooltip title={validationResult.message}>
                              <WarningOutlined/>
                            </CustomTooltip>
                          }
                        </Stack>
                      </TableCell>

                      <TableCell align="left">{mostRecentSession ? 
                        <CustomTooltip 
                          arrow 
                          placement="top" 
                          title={
                            <Stack direction="row">
                              <Typography noWrap>{mostRecentSession.session_id}</Typography>
                              <ContentCopy onClick={()=> handleCopyClick(mostRecentSession.session_id)}/>
                            </Stack>
                          }
                        >
                          <Typography>Session #{mostRecentSession.session_number}</Typography>
                          <Typography>{formatDate(mostRecentSession.date)}</Typography>
                          <Typography>{convertToNonMilitaryTime(mostRecentSession.date.slice(11,16))}</Typography>
                        </CustomTooltip>
                        : 
                          "No Sessions Yet"
                        }
                      </TableCell>

                      <TableCell align="left">
                        <Stack>
                          {"download_analyzed" in row && download_analyzed === false ? "" : 
                           <div style={{ display: 'flex', alignItems: 'center' }}>
                            <svg width="4" height="14" viewBox="0 0 4 14" fill="none">
                              <path d="M2 0.5V13.5" stroke="black" strokeWidth="3" />
                            </svg>
                            <Typography variant="body1" fontSize={13} sx={{ pl: 1 }}>
                              Download Analyzed
                            </Typography>
                           </div>
                          }
                          {"download_raw" in row && download_raw === false ? "" : 
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <svg width="4" height="14" viewBox="0 0 4 14" fill="none">
                                <path d="M2 0.5V13.5" stroke="black" strokeWidth="3" />
                              </svg>
                              <Typography variant="body1" fontSize={13} sx={{ pl: 1 }}>
                                Download Raw
                              </Typography>
                            </div>
                          }
                          {"download_metadata" in row && download_metadata === false ? "" : 
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <svg width="4" height="14" viewBox="0 0 4 14" fill="none">
                                <path d="M2 0.5V13.5" stroke="black" strokeWidth="3" />
                              </svg>
                              <Typography variant="body1" fontSize={13} sx={{ pl: 1 }}>
                                Download Meta
                              </Typography>
                            </div>
                          }

                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <svg width="4" height="14" viewBox="0 0 4 14" fill="none">
                              <path d="M2 0.5V13.5" stroke="black" strokeWidth="3" />
                            </svg>
                            <Typography variant="body1" fontSize={13} sx={{ pl: 1 }}>
                            {advanced ? "Advanced Metrics": "Basic Metrics"}
                            </Typography>
                          </div>

                          {istokenApiAnArray && token_api.length > 0 && 
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <svg width="4" height="14" viewBox="0 0 4 14" fill="none">
                                <path d="M2 0.5V13.5" stroke="black" strokeWidth="3" />
                              </svg>
                              <Typography variant="body1" fontSize={13} sx={{ pl: 1 }}>
                                  API Access
                              </Typography>
                            </div>
                          }

                          {/* <Chip
                            label={ */}
                              <Stack direction="row">
                              {productIds.map((product, index) => ( 
                                  <>
                                    {productIconMap[product.product_id]}
                                  </>
                                 )
                              )}
                            </Stack>
                          {/* } */}
                            {/* sx={{ width: "auto", pl: 1 }}
                            variant="outlined"
                          /> */}
                        </Stack>
                      </TableCell>

                      <TableCell align="left">
                        {row.org_type === 'trial' ?                             
                          <Chip
                            variant='outlined'
                            label={<Typography fontSize={14}>{row.org_type}</Typography>}
                          /> 
                        : 
                          (row.org_type ? 
                            row.org_type 
                            : 
                            'N/A'
                          )}
                        </TableCell>

                      <TableCell align="left">
                        <Stack direction="row" spacing={1}>
                            {remainingMembershipTime.differenceInDays < 0  ? <Cancel sx={{color: "error.main"}}/> : remainingMembershipTime.differenceInDays  < 10 ? <ReportProblem sx={{color: "warning.main"}}/> : <CheckCircle sx={{color: "success.main"}}/>}
                            <Typography>
                            {remainingMembershipTime.differenceInDays  < 0  ? `Expired` : remainingMembershipTime.differenceInDays  < 10 ? `${remainingMembershipTime.differenceInDays } days left` : `${remainingMembershipTime.differenceInDays } days`}
                            </Typography>
                        </Stack>
                        <Typography>{remainingMembershipTime.differenceInDays <= 0 ? "Expired on": "Expires on"}: {formatDate(remainingMembershipTime.expirationDate)}</Typography>
                      </TableCell>

                      <TableCell align="left">{expired ? "Yes" : 'No'}</TableCell>

                      
                      <TableCell align="left">             
                        <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                          <MoreVert/>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>

              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          Not found
                        </Typography>

                        <Typography variant="body2">
                          No results found for &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100, 250]}
            component="div"
            count={orgData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        
        </Container>
  
        <Popover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MenuItem>
              Reactivate
          </MenuItem>
          <MenuItem>
              Extend
          </MenuItem>
          <MenuItem>
              Upgrade Membership
          </MenuItem>
        </Popover>
      </>
    );
  }
}