import { filter } from 'lodash';
import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom';
// @mui
import {
  Box,
  Table,
  Stack,
  Paper,
  Chip,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Avatar,
  Tooltip,
} from '@mui/material';
import { ContentCopy, AssignmentIndOutlined, WarningOutlined, EditOutlined } from '@mui/icons-material';
import json from '../assets/json.png'
// components
// sections
import { UsersListHead, UsersListToolbar, EditUser } from '../sections/users';
// API Calls
import { listAllOrgUsers, putUserOrgDateDuration } from "../api/users";
// Utils
import { CustomTooltip } from '../utils/customStyles';
import { formatDate } from '../utils/formatFunctions';
import { handleCopyClick } from '../utils/copyFunction';
import { validateUserObject } from '../utils/validateFunctions';
import { handleShowJson } from '../utils/jsonFunction';
// ----------------------------------------------------------------------

const tableHead = [
  { id: 'name', label: 'Name', alignRight: false },  
  { id: 'organizations', label: 'Organizations', alignRight: false },
  { id: 'email', label: 'Contact', alignRight: false },
  { id: 'recentOrg', label: 'Recent Org', alignRight: false },
  { id: 'json', label: 'Json', alignRight: false },
];
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  
  if (query) {
    return stabilizedThis.filter(([user]) => {
      const { name, email, user_id, org_profiles } = user;
      const lowercaseQuery = query.toLowerCase();
      return (
        name.toLowerCase().includes(lowercaseQuery) ||
        email.toLowerCase().includes(lowercaseQuery) ||
        user_id.toLowerCase().includes(lowercaseQuery) ||
        org_profiles.some(org => org.organization_id.toLowerCase().includes(lowercaseQuery))
      );
    }).map(([filteredUser]) => filteredUser);
  }
  
  return stabilizedThis.map(([el]) => el);
}

export default function Organizations () {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(100);

  const [userData, setUserData] = useState(null)

  const [orgProfile, setOrgProfile] = useState(null)

  useEffect(()=> {

      const fetchUserData = async() => {
          try {
              const data = await listAllOrgUsers()
              const users = data.data
              const userOrgs = users.filter((user) => user.organization_id);
              userOrgs.forEach(async(org)=> {
                if(!org.hasOwnProperty('date_duration') ||
                !org.hasOwnProperty('expired')
                ){
                  console.log("No date duration!")
                  console.log(org)
                  const data = {
                    pkId: org.pk_id,
                    skId: org.sk_id
                  }
                  const response = await putUserOrgDateDuration(data)
                  console.log(response)
                }
              })

              const userProfiles = users.filter((user) => user.organization_id === undefined);

              const sortedUserOrgProfiles = userOrgs.sort((a, b) => a.user_id.localeCompare(b.user_id));

              // Sort user profiles by user ID
              const sortedUserProfiles = userProfiles.sort((a, b) => a.user_id.localeCompare(b.user_id));
          
              // Match user IDs between user profiles and organization profiles
              const matchedUserProfiles = sortedUserProfiles.map((userProfile) => {
                const org_profiles = sortedUserOrgProfiles
                  .filter((orgProfile) => orgProfile.user_id === userProfile.user_id)
                  // .map((orgProfile) => orgProfile.organization_id);
                
                return { ...userProfile, org_profiles };
              });

              setUserData(matchedUserProfiles)

          } catch(err) {
              console.log(err)
          }
      }

      fetchUserData()

  }, [])


  const handleOpenModal = (user) => {
    setOrgProfile(user)
    setOpen(true);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  if (userData) {

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userData.length) : 0;
  
    const filteredUsers = applySortFilter(userData, getComparator(order, orderBy), filterName);

    const isNotFound = !filteredUsers.length && !!filterName;
  
    return (
      <>
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              All Users
            </Typography>
          </Stack>
  
          <UsersListToolbar filterName={filterName} onFilterName={handleFilterByName} />
  
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UsersListHead
                order={order}
                orderBy={orderBy}
                headLabel={tableHead}
                rowCount={userData.length}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  const { id, name, date_created, user_id, email, recent_org, org_profiles } = row;
                  const missingdata = validateUserObject(row)
                  const { org_profiles: _, ...ddbentry } = row;

                  let warningClass = 'transparent';
              
                  if (missingdata.length > 0) {
                    const hasHeightOrWeight = missingdata.some(key => key === 'Height' || key === 'Weight');
                    warningClass = hasHeightOrWeight ? 'warning.dark' : 'warning.light';
                  }
      
                  return (
                    <TableRow key={id} tabIndex={-1} sx={{ backgroundColor: warningClass}}>
    
                      <TableCell component="th" scope="row" padding="none">
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <CustomTooltip 
                            arrow 
                            placement="top" 
                            title={ 
                              <Stack direction={"row"}>
                                <Typography fontSize={14}>{user_id}</Typography>
                                <IconButton>
                                  <ContentCopy onClick={()=> handleCopyClick(user_id)}/>
                                </IconButton>
                              </Stack>
                            }
                          >
                            <AssignmentIndOutlined />
                          </CustomTooltip>
                          {/* <Link 
                            to={`/dashboard/user/${name}`}  
                            style={{textDecoration: "none", color: "black"}}
                            state= {{ 
                              userName: name,
                              userId: user_id,
                            }}
                          > */}
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Stack>
                                <Typography noWrap variant='caption'>
                                  {name}
                                </Typography>
                          
                                <Typography noWrap>Created On: {formatDate(date_created)}</Typography>
                                
                              </Stack>
                              {missingdata.length > 0 && 
                                <CustomTooltip title={`Missing Fields are : ${missingdata}`}>
                                  <WarningOutlined/>
                                </CustomTooltip>
                              }
                            </Stack>
                          {/* </Link> */}
                        </Stack>
                      </TableCell>

                      <TableCell align="left">
                        {org_profiles && org_profiles.map((org) => (
                          <Chip
                            variant='outlined'
                            sx={{backgroundColor: "white"}}
                            label={
                              <CustomTooltip placement="top" title={
                                <>
                                <Typography>
                                  Total Sessions: {org.session_history_metadata.total_sessions}
                                </Typography>
                                <Typography>
                                  Is Active: {org.is_active === true ? "Yes": "No"}
                                </Typography>
                                <Typography>
                                  Type: {org.type}
                                </Typography>
                                </>
                              }
                              >
                                <Typography >{org.organization_id}</Typography>
                              </CustomTooltip>}  
                            icon={ 
                              <>
                                <IconButton>
                                  <Box 
                                    onClick={() => handleShowJson(org)}
                                    component="img"
                                    sx={{ width: 20, height: 20 }}
                                    src={json}
                                  /> 
                                </IconButton>   
                                <IconButton>
                                  <ContentCopy onClick={()=> handleCopyClick(org.organization_id)}/>
                                </IconButton>   
                                <IconButton>
                                  <EditOutlined onClick={() => handleOpenModal(org)}/>
                                </IconButton>  
                              </>                  
                            }
                          />
         
                        ))}
                      </TableCell>

                      <TableCell align="left">
                        <Typography>{email}</Typography>
                      </TableCell>

                      <TableCell align="left">
                        <Typography>{recent_org ? recent_org : "N/A" }</Typography>
                      </TableCell>
                      
                      <TableCell align="left">
                        <IconButton size="large" color="inherit">
                          <Box 
                            onClick={() => handleShowJson(ddbentry)}
                            component="img"
                            sx={{ width: 30, height: 30 }}
                            src={json}
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>

              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                      <Typography variant="h6" paragraph>
                        Not found
                      </Typography>

                      <Typography variant="body2">
                        No results found for &nbsp;
                        <strong>&quot;{filterName}&quot;</strong>.
                      </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
  
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100, 250]}
            component="div"
            count={userData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

        <EditUser 
          openModal={open} 
          handleCloseModal={()=> setOpen(false)} 
          orgProfile={orgProfile}
        />
        </Container>
      </>
    );
  }
}
