// List all Organizations
export const listAllOrgs = async () => {
    try {
      const response = await fetch(`/data/orgs`);
      const data = await response.json();
      console.log("All orgs:", data)
      return data
    } catch (error) {
      console.error('Error fetching org admins:', error);
    }
  }

// List all Organizations
export const listAllOrgDisplays = async () => {
  try {
    const response = await fetch(`/data/orgdisplays`);
    const data = await response.json();
    console.log("All org displays:", data)
    return data
  } catch (error) {
    console.error('Error fetching org admins:', error);
  }
}

// Find Org by Id
export const findOrgById = async (orgId) => {
    try {
      const response = await fetch(`/data/org/${orgId}`);
      const data = await response.json();
      console.log("Org:", data)
      return data
    } catch (error) {
      console.error('Error fetching Org:', error);
    }
}

// Update Org
export const updateOrganization = async (orgId, orgData, metrics) => {
  try {
    const response = await fetch(`/data/updateorg/${orgId}`, {
      method: 'PUT',
      body: JSON.stringify({orgData, metrics}),
      headers: {
        'Content-Type': 'application/json',
      }
    });
    const data = await response.json();
    console.log("Org Updated:", data)
    return data
  } catch (error) {
    console.error('Error fetching Org:', error);
  }
}

// Add Org Api token
export const addOrganizationApiToken = async (orgName, orgId, apiToken) => {
  console.log(orgName, apiToken)
  try {
    const response = await fetch(`/data/addapitoken`, {
      method: 'PUT',
      body: JSON.stringify({ orgName, orgId, apiToken }),
      headers: {
        'Content-Type': 'application/json',
      }
    });
    const data = await response.json();
    console.log("Org Updated:", data)
    return data
  } catch (error) {
    console.error('Error fetching Org:', error);
  }
}

// Edit API Token
export const editOrganizationApiToken = async (orgId, apiToken) => {
  try {
    const response = await fetch(`/data/editapitoken`, {
      method: 'PUT',
      body: JSON.stringify({ orgId, apiToken }),
      headers: {
        'Content-Type': 'application/json',
      }
    });
    const data = await response.json();
    console.log("Org Updated:", data)
    return data
  } catch (error) {
    console.error('Error fetching Org:', error);
  }
}


// Find Org by Id
export const getOrgUsers = async (orgId) => {
  try {
    const response = await fetch(`/data/orgusers/${orgId}`);
    const data = await response.json();
    console.log("Org Users:", data)
    return data
  } catch (error) {
    console.error('Error fetching Org:', error);
  }
}


// Get Org Info and All Users in an Org
export const getOrgInfoAndUsers = async (orgId) => {
  try {
    const response = await fetch(`/data/organdusers/${orgId}`);
    const data = await response.json();
    console.log("Org Users:", data)
    return data
  } catch (error) {
    console.error('Error fetching Org:', error);
  }
}

// Invite User via Email to Org
export const inviteUserToOrganization = async (email, organizationId) => {
  try {
    const response = await fetch(`/data/inviteuser/${email}/${organizationId}`);
    const data = await response.json();
    const status = response.status; 
    console.log("Invite User response:", data, "Invite User Status Code:", status)

    return { data, status }; 
  } catch (error) {
    console.error('Error fetching Org:', error);
  }
}


// Upgrade User to Admin via Email in Org
export const upgradeUserToAdminViaEmail = async (email, organizationId) => {
  try {
    const response = await fetch(`/data/upgradeusertoadmin/${email}/${organizationId}`);
    const data = await response.json();
    const status = response.status; 
    console.log("Upgrade Admin response:", data, "Upgrade Admin Status Code:", status)

    return { data, status }; 
  } catch (error) {
    console.error('Error fetching Org:', error);
  }
}

//Check if account is Being Used
export const accountCheck = async (orgId, email) => {
  try {
    const response = await fetch(`/data/accountcheck`, {
      method: "PUT",
      body: JSON.stringify({orgId, email}),
      headers: {
        'Content-Type': 'application/json',
      }
    });

    const data = await response.json();
    const statusCode = response.status; 
    return { data, statusCode}
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

// Fetch all Org Users in Org
export const createOrganization = async (orgData) => {
  try {
      const response = await fetch('/data/createorg', {
        method: 'POST',
        body: JSON.stringify(orgData),
        headers: {
          'Content-Type': 'application/json',
        }
      });
          
      const data = await response.json();
      return data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };