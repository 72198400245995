import { Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';

export const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "white",
      border: '1px solid',
      borderColor: theme.palette.primary.light,
      color: 'black',
      borderRadius: 0,
      fontSize: 14,
    },
      [`& .${tooltipClasses.arrow}`]: {
      color: "white",
      borderColor: theme.palette.primary.light,
      border: '1px solid',
    },
  }));

export const commonModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: 1,
  p: 4,
  width: "40%",
  maxWidth: "70%"
};