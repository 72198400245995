// @mui
import PropTypes from 'prop-types';
import { Box, Stack, Card, Divider, Typography, CardHeader } from '@mui/material';
// Components
// Utils
// ----------------------------------------------------------------------

UserDeviceList.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
};

export default function UserDeviceList ({ title, subheader, list, ...other }) {
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

        <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
          {list.map((device) => (
            <UserDevice key={device.id} device={device} />
          ))}
        </Stack>

      <Divider />

    </Card>
  );
}

// ----------------------------------------------------------------------

UserDevice.propTypes = {
  device: PropTypes.shape({
    date_created: PropTypes.string,
    custom_name: PropTypes.string,
    device_name: PropTypes.string,
    placement: PropTypes.string,
    firmware_version: PropTypes.string,
    id: PropTypes.string,
    product_type: PropTypes.string,
  }),
};

function UserDevice ({ device }) {
  const { date_created, custom_name, placement, device_name, firmware_version, product_type } = device;

  return (
    <Stack direction="row" alignItems="center" spacing={2}>

      <Box sx={{ minWidth: 240, flexGrow: 1 }}>
        <Typography color="inherit" variant="subtitle1" underline="hover" noWrap>
          Device Name: {device_name}
        </Typography>

        <Typography variant="body1" sx={{ color: 'text.secondary' }} noWrap>
          Custom Name: {custom_name}
        </Typography>

        <Typography variant="body1" sx={{ color: 'text.secondary' }} noWrap>
          Created On: {date_created.slice(0,10)}
        </Typography>

        <Typography variant="body1" sx={{ color: 'text.secondary' }} noWrap>
          Product Type: {product_type}
        </Typography>

        <Typography variant="body1" sx={{ color: 'text.secondary' }} noWrap>
          Placement: {placement}
        </Typography>

        <Typography variant="body1" sx={{ color: 'text.secondary' }} noWrap>
          Firmware Version: {firmware_version}
        </Typography>

      </Box>
    </Stack>
  );
}
