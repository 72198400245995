import React, { useState } from 'react';
// @mui
import {
  Stack, 
  Modal, 
  Divider, 
  Typography, 
  IconButton,
  TextField,
  Button,
  Card,
  CardHeader,
} from '@mui/material/';
// @mui-icons
import { Close } from '@mui/icons-material';
// Apis
import { addOrganizationApiToken } from '../../api/orgs';
// Utils
import { toISOLocal } from '../../utils/formatFunctions';
import { commonModalStyle } from '../../utils/customStyles';
// ----------------------------------------------------------------------

export default function CreateOrgApiToken ({
  orgName,
  orgId,
  openModal,
  handleCloseModal
}){

 const [loadingStatus, setLoadingStatus] = useState(false)
 const [errorMessage, setErrorMessage] = useState("")
 const [dateDuration, setDateDuration] = useState(365)

  const handleCreateOrgApiToken = async() => {
    const date = new Date()
    const formattedDate = toISOLocal(date)

    const apiToken = {
      expired: false,
      date_created: formattedDate,
      date_duration: dateDuration,
    }

    const response = await addOrganizationApiToken(orgName, orgId,apiToken)
    response.success ? handleCloseModal() : setErrorMessage('Something went wrong!')
  }

  return (
    <Modal
      open={openModal}
      hideBackdrop={false}
      onClose={handleCloseModal}
    >
      <Card sx={commonModalStyle}>
          <CardHeader 
              title={"Create API Token"} 
              action={
                  <IconButton onClick={handleCloseModal}>
                      <Close/>
                  </IconButton>
              }
          />

          <Divider/>

    
          <Stack spacing={2} mt={2}>
              <TextField 
                  label="Date Duration" 
                  fullWidth
                  value={dateDuration} 
              />

              <Typography>{errorMessage}</Typography>

              <Button
                  variant="contained"
                  onClick={(e)=> handleCreateOrgApiToken(e)}
                  sx={{width: "50%", alignSelf: 'center'}}
              >
                  Create API Token
              </Button>
          </Stack>
      </Card>
    </Modal>
  )
}