// @mui
import PropTypes from 'prop-types';
import { Box, Stack, Card, Divider, Typography, CardHeader } from '@mui/material';
// Components
// Utils
// ----------------------------------------------------------------------

UserInfoList.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
};

export default function UserInfoList ({ title, subheader, list, ...other }) {
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

        <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
          {list.map((info) => (
            <UserInfo key={info.id} info={info} />
          ))}
        </Stack>

      <Divider />

    </Card>
  );
}

// ----------------------------------------------------------------------

UserInfo.propTypes = {
  info: PropTypes.shape({
    createdOn: PropTypes.string,
    orgId: PropTypes.string,
    updatedOn: PropTypes.string,
    userId: PropTypes.string,
  }),
};

function UserInfo ({ info }) {
  const { userId, createdOn, updatedOn, type, orgId } = info;

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      
      <Box sx={{ minWidth: 240, flexGrow: 1 }}>
        <Typography color="inherit" variant="subtitle1" underline="hover" noWrap>
          {type}
        </Typography>

        <Typography variant="body1" sx={{ color: 'text.secondary' }} noWrap>
          Org Id: {orgId}
        </Typography>

        <Typography variant="body1" sx={{ color: 'text.secondary' }} noWrap>
          User Id: {userId}
        </Typography>

        <Typography variant="body1" sx={{ color: 'text.secondary' }} noWrap>
          Created On: {createdOn}
        </Typography>

        <Typography variant="body1" sx={{ color: 'text.secondary' }} noWrap>
          Updated On: {updatedOn}
        </Typography>

      </Box>
    </Stack>
  );
}
