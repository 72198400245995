import React, { useEffect, useState } from 'react';
// @mui
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Stack, 
  Modal, 
  Divider, 
  Typography, 
  IconButton,
  TextField,
  Card,
  CardHeader,
  Switch,
} from '@mui/material/';
import { LoadingButton } from '@mui/lab';
// @mui-icons
import { Close } from '@mui/icons-material';
// APIs
import { updateUserOrgProfile } from '../../api/users';
// Utils
import { toISOLocal, formatDate, calculateExpirationDate } from '../../utils/formatFunctions';
import { commonModalStyle } from '../../utils/customStyles';
// ----------------------------------------------------------------------

export default function EditUser ({
  openModal,
  handleCloseModal,
  orgProfile
}){
 const [errorMessage, setErrorMessage] = useState("")
 const [expirationDate, setExpirationDate] = useState(null)
 const [expired, setExpired] = useState(null)

useEffect(()=> {
    if(orgProfile){
        console.log(orgProfile)
        if(orgProfile.hasOwnProperty('date_duration')) {
            console.log(orgProfile.date_duration)
            const date = formatDate(calculateExpirationDate(orgProfile.date_created, orgProfile.date_duration))
            console.log(date)
            setExpirationDate(date)
        } 

        if(orgProfile.hasOwnProperty('expired')) {
            console.log(orgProfile.expired)
            setExpired(orgProfile.expired)
        } 
    }
}, [orgProfile])

function calculateDaysDifference(startDateStr, endDateStr) {
  const startDate = new Date(startDateStr);
  
  // Parse end date string in mm/dd/yyyy format
  const [month, day, year] = endDateStr.split('/');
  const endDate = new Date(`${year}-${month}-${day}`);
  
  const differenceInTime = endDate.getTime() - startDate.getTime();
  const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
  return differenceInDays;
}

const handleSuccessfulUpdate = () => {
    // setSnackbarState({ 
    //   ...snackbarState, 
    //   open: true, 
    //   text: "The Organization has been successfully updated!" 
    // });
    handleCloseModal()
}
console.log(orgProfile, expirationDate)

const handleUserUpdate = async() => {
  const date = new Date()
  const formattedDate = toISOLocal(date)
  const convertedExpirationDate = calculateDaysDifference(orgProfile.date_created.slice(0,10), expirationDate)

  const updatedData = {
    pkId: orgProfile.pk_id,
    skId: orgProfile.sk_id, 
    dateDuration: convertedExpirationDate, 
    dateUpdated: formattedDate,
    expired: expired
  }

  console.log(updatedData)
  const response = await updateUserOrgProfile(updatedData)
  response.success === true ? handleSuccessfulUpdate() : console.log("User not updated")
}


// const handleSubmit = async (e) => {
//   if (!organizationName) {
//     setErrorMessage("Please Enter an Organization Name!")
//   } else if (organizationName.length < 3){
//     setErrorMessage("Please enter 3 characters")
//   } else {
//     handleOrgUpdate()
//   }
// }

    if (orgProfile) {
        return (
        <Modal
        open={openModal}
        hideBackdrop={false}
        onClose={handleCloseModal}
        >
            <Card sx={commonModalStyle}>
                <CardHeader 
                    title={"Edit User"} 
                    action={
                    <IconButton onClick={handleCloseModal}>
                        <Close/>
                    </IconButton> 
                    }
                />
    
                <Divider/>
    
                <Stack spacing={2} mt={2}>
    
                <Typography>
                    Date Created: {formatDate(orgProfile.date_created)}
                </Typography>

                    
                <Typography>
                    Date to Expire: {formatDate(calculateExpirationDate(orgProfile.date_created, orgProfile.date_duration))}
                </Typography>
    
                <Typography>
                    Is Active: {orgProfile.is_active ? "Yes": "No"}
                </Typography>
    
                <Typography>
                    Org Id: {orgProfile.organization_id}
                </Typography>
    
                <Typography>
                    Type: {orgProfile.type}
                </Typography>
    
                <Typography>
                    Total Sessions: {orgProfile.session_history_metadata.total_sessions}
                </Typography>
    
                <TextField 
                    label="Date to Expire (MM/DD/YYYY)" 
                    fullWidth
                    value={expirationDate}
                    onChange={(e) => {
                        setExpirationDate(e.target.value);
                    }}
                />
    
                {orgProfile.hasOwnProperty('expired') && 
                
                <FormControl>
                    <FormLabel>Expired</FormLabel>
                    <FormControlLabel 
                        control={
                            <Switch
                                checked={expired}
                                onChange={(e) => {
                                    setExpired(e.target.checked);
                                }}
                            />
                        } 
                    />
                </FormControl>
                }
    
                <Typography>{errorMessage}</Typography>
    
                <LoadingButton
                    variant="contained"
                    onClick={(e)=> handleUserUpdate(e)}
                    sx={{width: "50%", alignSelf: 'center'}}
                >
                    Update User Org Profile
                </LoadingButton>
                </Stack>
            </Card>
        </Modal>
        )
    }
}