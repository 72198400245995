import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
// @mui
import { Stack, Typography, Table, Menu, MenuItem, TableCell, TableRow, TableContainer, TableBody, IconButton, Switch, FormControlLabel, Box, Toolbar, TablePagination, Popover } from '@mui/material';
import { ContentCopy, InfoOutlined, AssignmentIndOutlined, MoreVert, WarningOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import json from '../../assets/json.png'
// APIs
import { demoteAdminToUser, getUserMetadata } from '../../api/users';
// Components
import { OrgListHead } from '../orgs';
import { EditMetadata } from '../org'
// Utils
import { handleShowJson } from '../../utils/jsonFunction';
import { handleCopyClick } from '../../utils/copyFunction';
import { formatDate } from '../../utils/formatFunctions';
import { CustomTooltip } from '../../utils/customStyles';
import { validateUserObject } from '../../utils/validateFunctions';
import { upgradeUserToAdminViaEmail } from '../../api/orgs';
// ----------------------------------------------------------------------

OrgUserList.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
  setOrgAdmins: PropTypes.func
};

export default function OrgUserList ({ title, subheader, action, setOrgAdmins, list, orgSessions, ...other }) {
  const [checked, setChecked] = useState(false)
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25); // Adjust as needed

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const tableHead = [
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'recentSession', label: 'Recent Session', alignRight: false },
    { id: 'totalSessions', label: 'Total Sessions', alignRight: false },
    { id: 'userType', label: 'User Type', alignRight: false },
    { id: 'upgrade', label: 'Upgrade/Downgrade', alignRight: false },
    { id: 'menu', label: '', alignRight: false },
  ];

  const findMostRecentSession = (user, sessions) => {
    const orgSessions = sessions.filter(session => session.user_id === user);
    if (orgSessions.length === 0) return null;
    return orgSessions.reduce((prev, current) => {
      return (new Date(current.date) > new Date(prev.date)) ? current : prev;
    });
  };

  const filterUsers = (list, sessions) => {
   return list
    .map(user => {
        const recentSession = findMostRecentSession(user.userId, sessions);
        return { ...user, recentSession };
    })
    .sort((user1, user2) => {
        if (!user1.recentSession) return 1;
        if (!user2.recentSession) return -1;
        return new Date(user2.recentSession.date) - new Date(user1.recentSession.date);
    });
  };
  
  const sortedUsers = filterUsers(list, orgSessions);
  const expiredUsers = sortedUsers.filter(ele => ele.isActive === false)
  const activeUsers = sortedUsers.filter(ele => ele.isActive === true)
  const users = checked ? expiredUsers : activeUsers

  return (
    <TableContainer sx={{ minWidth: 800 }}>
      <Toolbar sx={{justifyContent: "space-between"}}>
        <FormControlLabel
          control={
            <Switch checked={checked} onChange={handleChange}/>
          }
          label={checked ? "Inactive" : "Active"}
        />
        {action}
      </Toolbar>
      <Table>
        <OrgListHead headLabel={tableHead}/>
        <TableBody>
          {users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
            <OrgUser key={row.userId} user={row} setOrgAdmins={setOrgAdmins} />
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]} 
        component="div"
        count={users.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}

// ----------------------------------------------------------------------

// OrgUser.propTypes = {
//   users: PropTypes.shape({
//     name: PropTypes.string,
//     userId: PropTypes.string,
//     orgId: PropTypes.string,
//   }),
//   setOrgAdmins: PropTypes.func,
// };

function OrgUser ({ user, setOrgAdmins }) {
  const { name, email, userId, orgId, adminNotes, totalSessions, userType, dateCreated, isActive, recentSession } = user;
  
  const [loadingStatus, setLoadingStatus] = useState(false)
  const [color, setColor] = useState('primary')
  const [userData, setUserData] = useState({})
  const [missingMetadata, setMissingMetadata] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [open, setOpen] = useState(null);

  useEffect(()=> {

    const getData = async(userId) => {
      const response = await getUserMetadata(userId);
      const missingdata = validateUserObject(response.data)
      setMissingMetadata(missingdata)
      setUserData(response.data)
    }

    if(userId){
      getData(userId)
    }
  }, [user])

  const handleClick = (event) => {
    setOpen(event.currentTarget);
  };
  
  const handleClose = () => {
    setOpen(null)
  };

  const handleUpgrade = async (email, org) => {

    setLoadingStatus(true)

    const response = await upgradeUserToAdminViaEmail(email, org)
    const { data , status } = response
    console.log("Upgrade response", data.data.message)
    if (status === 200){
      setLoadingStatus(false)
      setColor('success')
      setOrgAdmins("")
    } else {
      setLoadingStatus(false)
      setColor('error')
    }
  }

  const handleDowngrade = async (userId, orgId) => {

    setLoadingStatus(true)

    const response = await demoteAdminToUser(userId, orgId)

    const { success , message } = response
    if(success){
      setLoadingStatus(false)
      setColor('success')
      setOrgAdmins("")
    } else {
      setLoadingStatus(false)
      setColor('error')
    }
  }

  const handleButtonClick = () => {
    if (userType === 'admin') {
      handleDowngrade(userId, orgId);
    } else {
      handleUpgrade(email, orgId);
    }
  };

  const handleMenuItemClick = () => {
    setOpenModal(true);
    setOpen(null)
  };

  if (missingMetadata) {
    let warningClass = 'transparent';

    if (missingMetadata.length > 0) {
      const hasHeightOrWeight = missingMetadata.some(key => key === 'Height' || key === 'Weight');
      warningClass = hasHeightOrWeight ? 'warning.dark' : 'warning.light';
  }

    return (
      <TableRow tabIndex={-1} sx={{ backgroundColor: warningClass}}>
        <TableCell component="th" scope="row">
          <Stack direction="row" alignItems="center" spacing={1}>
            <CustomTooltip 
              arrow 
              placement="top" 
              title={ 
                <Stack direction={"row"}>
                  <Typography fontSize={14}>{userId}</Typography>
                  <ContentCopy onClick={()=> handleCopyClick(userId)}/>
                </Stack>
              }
            >
              <AssignmentIndOutlined />
            </CustomTooltip>
  
            <Stack>
              <Typography noWrap variant='caption'>
                {name}
              </Typography>
              <Typography>Email: {email}</Typography>
              <Typography>Created On: {formatDate(dateCreated)}</Typography>
            </Stack>
  
            <IconButton>
              <Box 
                onClick={() => handleShowJson(user)}
                component="img"
                sx={{ width: 30, height: 30 }}
                src={json}
              />
            </IconButton>

            {adminNotes && 
              <CustomTooltip arrow placement="top" title={adminNotes}>
                <InfoOutlined/>
              </CustomTooltip>
            }

            {missingMetadata.length > 0 && 
              <CustomTooltip title={`Missing Fields are : ${missingMetadata}`}>
                <WarningOutlined/>
              </CustomTooltip>
            }
            </Stack>
  
        </TableCell>
  
        <TableCell>
          <Typography>{recentSession ? formatDate(recentSession.date) : "N/A"}</Typography>
        </TableCell>
  
        <TableCell>
          <Typography>{totalSessions}</Typography>
        </TableCell>
  
        <TableCell>
          <Typography>{userType}</Typography>
        </TableCell>
  
        <TableCell>
          <LoadingButton
            variant='contained'
            loading={loadingStatus}
            color={color}
            onClick={handleButtonClick}
          >
            {userType === 'admin' ? 'Downgrade' : 'Upgrade'}
          </LoadingButton>
        </TableCell>
  
        <TableCell>
          <IconButton onClick={handleClick}>
            <MoreVert/>
          </IconButton>
        </TableCell>

        <EditMetadata 
          openModal={openModal} 
          handleCloseModal={()=> setOpenModal(false)} 
          userData={userData}
          orgId={orgId}
        />

        <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={()=> handleClose()}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={() => handleMenuItemClick()}>Edit Metadata</MenuItem>
      </Popover>
      </TableRow>

      
    );
  }
}
