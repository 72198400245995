// List all org sessions
export const listAllOrgSessions = async () => {
    try {
      const response = await fetch(`/data/sessions`);
      const data = await response.json();
      console.log("All org sessions:", data)
      return data
    } catch (error) {
      console.error('Error fetching org sessions:', error);
    }
}

// Find Session by Id
export const findSessionById = async (sessionId) => {
    try {
      const response = await fetch(`/data/session/${sessionId}`);
      const data = await response.json();
      console.log("Session:", data)
      return data
    } catch (error) {
      console.error('Error fetching session:', error);
    }
}
