export const metrics = {
    baseballadvancedjumps: {
        "pk_id": "baseball-advanced+jumps",
        "sk_id": "display",
        "product_list": [
            {
                "product_id": "P-ARM_SLEEVE",
                "display_color": "#FFFFFF",
                "display_name": ""
            },
            {
                "product_id": "P-SURFACE_FABRIC",
                "display_color": "#FFFFFF",
                "display_name": ""
            }
        ],
        "product_map": {
            "P-ARM_SLEEVE": {
                "product_id": "P-ARM_SLEEVE",
                "display_color": "#FFFFFF",
                "display_name": ""
            },
            "P-SURFACE_FABRIC": {
                "product_id": "P-SURFACE_FABRIC",
                "display_color": "#FFFFFF",
                "display_name": ""
            }
        },
        "activity_list": [
            {
                "activity_id": "A-SQUAT_JUMP",
                "display_color": "#4242BB",
                "display_name": "Squat Jump",
                "gsi_category": "Jumps",
                "metadata": null
            },
            {
                "activity_id": "A-CMJ",
                "display_color": "#04CCCB",
                "display_name": "Countermovement Jump",
                "gsi_category": "Jumps",
                "metadata": null
            },
            {
                "activity_id": "A-LONGTOSS",
                "display_color": "#5C9EF2",
                "display_name": "Long Toss",
                "gsi_category": "Baseball",
                "metadata": null
            },
            {
                "activity_id": "A-PLYO_THROW",
                "display_color": "#FF8863",
                "display_name": "Plyo Throw",
                "gsi_category": "Baseball",
                "metadata": [
                    {
                        "display_name": "Ball weight",
                        "key": "ball_weight",
                        "units": "oz",
                        "values": [
                            "3.5",
                            "4.4",
                            "5.25",
                            "8",
                            "10",
                            "12",
                            "16",
                            "18",
                            "35",
                            "53",
                            "70.5"
                        ]
                    }
                ]
            },
            {
                "activity_id": "A-THROW_WARMUP",
                "display_color": "#1EA698",
                "display_name": "Warmup Throws",
                "gsi_category": "Baseball",
                "metadata": null
            },
            {
                "activity_id": "A-CMJ_ONE_LEG",
                "display_color": "#82B11D",
                "display_name": "Single Leg Jump",
                "gsi_category": "Jumps",
                "metadata": null
            },
            {
                "activity_id": "A-PITCHING",
                "display_color": "#FFC04D",
                "display_name": "Pitching",
                "gsi_category": "Baseball",
                "metadata": null
            }
        ],
        "activity_map": {
            "A-SQUAT_JUMP": {
                "activity_id": "A-SQUAT_JUMP",
                "display_color": "#4242BB",
                "display_name": "Squat Jump",
                "gsi_category": "Jumps",
                "metadata": null
            },
            "A-CMJ": {
                "activity_id": "A-CMJ",
                "display_color": "#04CCCB",
                "display_name": "Countermovement Jump",
                "gsi_category": "Jumps",
                "metadata": null
            },
            "A-LONGTOSS": {
                "activity_id": "A-LONGTOSS",
                "display_color": "#5C9EF2",
                "display_name": "Long Toss",
                "gsi_category": "Baseball",
                "metadata": null
            },
            "A-PLYO_THROW": {
                "activity_id": "A-PLYO_THROW",
                "display_color": "#FF8863",
                "display_name": "Plyo Throw",
                "gsi_category": "Baseball",
                "metadata": [
                    {
                        "display_name": "Ball weight",
                        "key": "ball_weight",
                        "units": "oz",
                        "values": [
                            "3.5",
                            "4.4",
                            "5.25",
                            "8",
                            "10",
                            "12",
                            "16",
                            "18",
                            "35",
                            "53",
                            "70.5"
                        ]
                    }
                ]
            },
            "A-THROW_WARMUP": {
                "activity_id": "A-THROW_WARMUP",
                "display_color": "#1EA698",
                "display_name": "Warmup Throws",
                "gsi_category": "Baseball",
                "metadata": null
            },
            "A-CMJ_ONE_LEG": {
                "activity_id": "A-CMJ_ONE_LEG",
                "display_color": "#82B11D",
                "display_name": "Single Leg Jump",
                "gsi_category": "Jumps",
                "metadata": null
            },
            "A-PITCHING": {
                "activity_id": "A-PITCHING",
                "display_color": "#FFC04D",
                "display_name": "Pitching",
                "gsi_category": "Baseball",
                "metadata": null
            }
        },
        "product_activity_mapping": {
            "P-ARM_SLEEVE": [
                "A-PLYO_THROW",
                "A-LONGTOSS",
                "A-PITCHING",
                "A-THROW_WARMUP"
            ],
            "P-SURFACE_FABRIC": [
                "A-CMJ_ONE_LEG",
                "A-CMJ",
                "A-SQUAT_JUMP"
            ]
        },
        "plot_keys": {
            "overhand_throw_v3a": {
                "plot_keys": [
                    {
                        "metric_id": "intensity_zone_ai",
                        "display_name": "Personalized (AI) Intensity Zone",
                        "units": "",
                        "plot": true,
                        "aggregation_config": {
                            "function": "last",
                            "short_name": "zone"
                        }
                    },
                    {
                        "metric_id": "peak_varus_torque",
                        "display_name": "Peak Varus Torque",
                        "units": "Nm",
                        "plot": true,
                        "aggregation_config": {
                            "function": "last",
                            "short_name": "torque"
                        }
                    },
                    {
                        "metric_id": "arm_velocity",
                        "display_name": "Arm Velocity",
                        "units": "mph",
                        "plot": true,
                        "aggregation_config": {
                            "function": "last",
                            "short_name": "velo"
                        }
                    }
                ]
            },
            "counter_movement_jump": {
                "plot_keys": [
                    {
                        "metric_id": "height",
                        "display_name": "Jump Height",
                        "units": "In",
                        "plot": true,
                        "aggregation_config": {
                            "function": "last",
                            "short_name": "height"
                        }
                    },
                    {
                        "metric_id": "rsi_mod",
                        "display_name": "RSI",
                        "units": "m/s",
                        "plot": true,
                        "aggregation_config": {
                            "function": "last",
                            "short_name": "rsi"
                        }
                    },
                    {
                        "metric_id": "symmetry_score",
                        "display_name": "Symmetry Score",
                        "units": "%",
                        "plot": true,
                        "aggregation_config": {
                            "function": "last",
                            "short_name": "sym"
                        }
                    }
                ]
            },
            "squat_jump_v3": {
                "plot_keys": [
                    {
                        "metric_id": "height",
                        "display_name": "Jump Height",
                        "units": "In",
                        "plot": true,
                        "aggregation_config": {
                            "function": "last",
                            "short_name": "height"
                        }
                    },
                    {
                        "metric_id": "rsi_mod",
                        "display_name": "RSI",
                        "units": "m/s",
                        "plot": true,
                        "aggregation_config": {
                            "function": "last",
                            "short_name": "rsi"
                        }
                    },
                    {
                        "metric_id": "symmetry_score",
                        "display_name": "Symmetry Score",
                        "units": "%",
                        "plot": true,
                        "aggregation_config": {
                            "function": "last",
                            "short_name": "sym"
                        }
                    }
                ]
            },
            "one_leg_counter_movement_jump_v3": {
                "plot_keys": [
                    {
                        "metric_id": "height",
                        "display_name": "Jump Height",
                        "units": "In",
                        "plot": true,
                        "aggregation_config": {
                            "function": "last",
                            "short_name": "height"
                        }
                    },
                    {
                        "metric_id": "rsi_mod",
                        "display_name": "RSI",
                        "units": "m/s",
                        "plot": true,
                        "aggregation_config": {
                            "function": "last",
                            "short_name": "rsi"
                        }
                    },
                    {
                        "metric_id": "takeoff_duration",
                        "display_name": "Time to Takeoff",
                        "units": "ms",
                        "plot": true,
                        "aggregation_config": {
                            "function": "last",
                            "short_name": "takeoff"
                        }
                    }
                ]
            }
        },
        "export_keys": {
            "overhand_throw_v3a": [
                {
                    "metric_id": "intensity_zone_ai",
                    "display_name": "Personalized (AI) Intensity Zone",
                    "units": "",
                    "plot": true,
                    "aggregation_config": {
                        "function": "last",
                        "short_name": "zone"
                    }
                },
                {
                    "metric_id": "peak_varus_torque",
                    "display_name": "Peak Varus Torque",
                    "units": "Nm",
                    "plot": true,
                    "aggregation_config": {
                        "function": "last",
                        "short_name": "torque"
                    }
                },
                {
                    "metric_id": "arm_velocity",
                    "display_name": "Arm Velocity",
                    "units": "mph",
                    "plot": true,
                    "aggregation_config": {
                        "function": "last",
                        "short_name": "velo"
                    }
                }
            ],
            "counter_movement_jump": [
                {
                    "metric_id": "height",
                    "display_name": "Jump Height",
                    "units": "In",
                    "plot": true,
                    "aggregation_config": {
                        "function": "last",
                        "short_name": "height"
                    }
                },
                {
                    "metric_id": "rsi_mod",
                    "display_name": "RSI",
                    "units": "m/s",
                    "plot": true,
                    "aggregation_config": {
                        "function": "last",
                        "short_name": "rsi"
                    }
                },
                {
                    "metric_id": "symmetry_score",
                    "display_name": "Symmetry Score",
                    "units": "%",
                    "plot": true,
                    "aggregation_config": {
                        "function": "last",
                        "short_name": "sym"
                    }
                }
            ],
            "squat_jump_v3": [
                {
                    "metric_id": "height",
                    "display_name": "Jump Height",
                    "units": "In",
                    "plot": true,
                    "aggregation_config": {
                        "function": "last",
                        "short_name": "height"
                    }
                },
                {
                    "metric_id": "rsi_mod",
                    "display_name": "RSI",
                    "units": "m/s",
                    "plot": true,
                    "aggregation_config": {
                        "function": "last",
                        "short_name": "rsi"
                    }
                },
                {
                    "metric_id": "symmetry_score",
                    "display_name": "Symmetry Score",
                    "units": "%",
                    "plot": true,
                    "aggregation_config": {
                        "function": "last",
                        "short_name": "sym"
                    }
                }
            ],
            "one_leg_counter_movement_jump_v3": [
                {
                    "metric_id": "height",
                    "display_name": "Jump Height",
                    "units": "In",
                    "plot": true,
                    "aggregation_config": {
                        "function": "last",
                        "short_name": "height"
                    }
                },
                {
                    "metric_id": "rsi_mod",
                    "display_name": "RSI",
                    "units": "m/s",
                    "plot": true,
                    "aggregation_config": {
                        "function": "last",
                        "short_name": "rsi"
                    }
                },
                {
                    "metric_id": "takeoff_duration",
                    "display_name": "Time to Takeoff",
                    "units": "ms",
                    "plot": true,
                    "aggregation_config": {
                        "function": "last",
                        "short_name": "takeoff"
                    }
                }
            ]
        }
    },


    baseballbasicjumps: {
        "pk_id": "baseball-basic+jumps",
        "sk_id": "display",
        "product_list": [
            {
                "product_id": "P-ARM_SLEEVE",
                "display_color": "#FFFFFF",
                "display_name": ""
            },
            {
                "product_id": "P-SURFACE_FABRIC",
                "display_color": "#FFFFFF",
                "display_name": ""
            }
        ],
        "product_map": {
            "P-ARM_SLEEVE": {
                "product_id": "P-ARM_SLEEVE",
                "display_color": "#FFFFFF",
                "display_name": ""
            },
            "P-SURFACE_FABRIC": {
                "product_id": "P-SURFACE_FABRIC",
                "display_color": "#FFFFFF",
                "display_name": ""
            }
        },
        "activity_list": [
            {
                "activity_id": "A-PITCHING",
                "display_color": "#FFC04D",
                "display_name": "Pitching",
                "gsi_category": "Baseball",
                "metadata": null
            },
            {
                "activity_id": "A-PLYO_THROW",
                "display_color": "#FF8863",
                "display_name": "Plyo Throw",
                "gsi_category": "Baseball",
                "metadata": [
                    {
                        "display_name": "Ball weight",
                        "key": "ball_weight",
                        "units": "oz",
                        "values": [
                            "3.5",
                            "4.4",
                            "5.25",
                            "8",
                            "10",
                            "12",
                            "16",
                            "18",
                            "35",
                            "53",
                            "70.5"
                        ]
                    }
                ]
            },
            {
                "activity_id": "A-CMJ",
                "display_color": "#04CCCB",
                "display_name": "Countermovement Jump",
                "gsi_category": "Jumps",
                "metadata": null
            },
            {
                "activity_id": "A-THROW_WARMUP",
                "display_color": "#1EA698",
                "display_name": "Warmup Throws",
                "gsi_category": "Baseball",
                "metadata": null
            },
            {
                "activity_id": "A-SQUAT_JUMP",
                "display_color": "#4242BB",
                "display_name": "Squat Jump",
                "gsi_category": "Jumps",
                "metadata": null
            },
            {
                "activity_id": "A-CMJ_ONE_LEG",
                "display_color": "#82B11D",
                "display_name": "Single Leg Jump",
                "gsi_category": "Jumps",
                "metadata": null
            },
            {
                "activity_id": "A-LONGTOSS",
                "display_color": "#5C9EF2",
                "display_name": "Long Toss",
                "gsi_category": "Baseball",
                "metadata": null
            }
        ],
        "activity_map": {
            "A-PITCHING": {
                "activity_id": "A-PITCHING",
                "display_color": "#FFC04D",
                "display_name": "Pitching",
                "gsi_category": "Baseball",
                "metadata": null
            },
            "A-PLYO_THROW": {
                "activity_id": "A-PLYO_THROW",
                "display_color": "#FF8863",
                "display_name": "Plyo Throw",
                "gsi_category": "Baseball",
                "metadata": [
                    {
                        "display_name": "Ball weight",
                        "key": "ball_weight",
                        "units": "oz",
                        "values": [
                            "3.5",
                            "4.4",
                            "5.25",
                            "8",
                            "10",
                            "12",
                            "16",
                            "18",
                            "35",
                            "53",
                            "70.5"
                        ]
                    }
                ]
            },
            "A-CMJ": {
                "activity_id": "A-CMJ",
                "display_color": "#04CCCB",
                "display_name": "Countermovement Jump",
                "gsi_category": "Jumps",
                "metadata": null
            },
            "A-THROW_WARMUP": {
                "activity_id": "A-THROW_WARMUP",
                "display_color": "#1EA698",
                "display_name": "Warmup Throws",
                "gsi_category": "Baseball",
                "metadata": null
            },
            "A-SQUAT_JUMP": {
                "activity_id": "A-SQUAT_JUMP",
                "display_color": "#4242BB",
                "display_name": "Squat Jump",
                "gsi_category": "Jumps",
                "metadata": null
            },
            "A-CMJ_ONE_LEG": {
                "activity_id": "A-CMJ_ONE_LEG",
                "display_color": "#82B11D",
                "display_name": "Single Leg Jump",
                "gsi_category": "Jumps",
                "metadata": null
            },
            "A-LONGTOSS": {
                "activity_id": "A-LONGTOSS",
                "display_color": "#5C9EF2",
                "display_name": "Long Toss",
                "gsi_category": "Baseball",
                "metadata": null
            }
        },
        "product_activity_mapping": {
            "P-ARM_SLEEVE": [
                "A-PITCHING",
                "A-PLYO_THROW",
                "A-LONGTOSS",
                "A-THROW_WARMUP"
            ],
            "P-SURFACE_FABRIC": [
                "A-CMJ",
                "A-SQUAT_JUMP",
                "A-CMJ_ONE_LEG"
            ]
        },
        "plot_keys": {
            "overhand_throw_v3a": {
                "plot_keys": [
                    {
                        "metric_id": "intensity_zone",
                        "display_name": "Throw Intensity",
                        "units": "",
                        "plot": true,
                        "aggregation_config": {
                            "function": "last",
                            "short_name": "zone"
                        }
                    },
                    {
                        "metric_id": "peak_varus_torque",
                        "display_name": "Peak Varus Torque",
                        "units": "Nm",
                        "plot": true,
                        "aggregation_config": {
                            "function": "last",
                            "short_name": "torque"
                        }
                    },
                    {
                        "metric_id": "arm_velocity",
                        "display_name": "Arm Velocity",
                        "units": "mph",
                        "plot": true,
                        "aggregation_config": {
                            "function": "last",
                            "short_name": "velo"
                        }
                    }
                ]
            },
            "counter_movement_jump": {
                "plot_keys": [
                    {
                        "metric_id": "height",
                        "display_name": "Jump Height",
                        "units": "In",
                        "plot": true,
                        "aggregation_config": {
                            "function": "last",
                            "short_name": "height"
                        }
                    },
                    {
                        "metric_id": "rsi_mod",
                        "display_name": "RSI",
                        "units": "m/s",
                        "plot": true,
                        "aggregation_config": {
                            "function": "last",
                            "short_name": "rsi"
                        }
                    },
                    {
                        "metric_id": "symmetry_score",
                        "display_name": "Symmetry Score",
                        "units": "%",
                        "plot": true,
                        "aggregation_config": {
                            "function": "last",
                            "short_name": "sym"
                        }
                    }
                ]
            },
            "squat_jump_v3": {
                "plot_keys": [
                    {
                        "metric_id": "height",
                        "display_name": "Jump Height",
                        "units": "In",
                        "plot": true,
                        "aggregation_config": {
                            "function": "last",
                            "short_name": "height"
                        }
                    },
                    {
                        "metric_id": "rsi_mod",
                        "display_name": "RSI",
                        "units": "m/s",
                        "plot": true,
                        "aggregation_config": {
                            "function": "last",
                            "short_name": "rsi"
                        }
                    },
                    {
                        "metric_id": "symmetry_score",
                        "display_name": "Symmetry Score",
                        "units": "%",
                        "plot": true,
                        "aggregation_config": {
                            "function": "last",
                            "short_name": "sym"
                        }
                    }
                ]
            },
            "one_leg_counter_movement_jump_v3": {
                "plot_keys": [
                    {
                        "metric_id": "height",
                        "display_name": "Jump Height",
                        "units": "In",
                        "plot": true,
                        "aggregation_config": {
                            "function": "last",
                            "short_name": "height"
                        }
                    },
                    {
                        "metric_id": "rsi_mod",
                        "display_name": "RSI",
                        "units": "m/s",
                        "plot": true,
                        "aggregation_config": {
                            "function": "last",
                            "short_name": "rsi"
                        }
                    },
                    {
                        "metric_id": "takeoff_duration",
                        "display_name": "Time to Takeoff",
                        "units": "ms",
                        "plot": true,
                        "aggregation_config": {
                            "function": "last",
                            "short_name": "takeoff"
                        }
                    }
                ]
            }
        },
        "export_keys": {
            "overhand_throw_v3a": [
                {
                    "metric_id": "intensity_zone",
                    "display_name": "Throw Intensity",
                    "units": "",
                    "plot": true,
                    "aggregation_config": {
                        "function": "last",
                        "short_name": "zone"
                    }
                },
                {
                    "metric_id": "peak_varus_torque",
                    "display_name": "Peak Varus Torque",
                    "units": "Nm",
                    "plot": true,
                    "aggregation_config": {
                        "function": "last",
                        "short_name": "torque"
                    }
                },
                {
                    "metric_id": "arm_velocity",
                    "display_name": "Arm Velocity",
                    "units": "mph",
                    "plot": true,
                    "aggregation_config": {
                        "function": "last",
                        "short_name": "velo"
                    }
                }
            ],
            "counter_movement_jump": [
                {
                    "metric_id": "height",
                    "display_name": "Jump Height",
                    "units": "In",
                    "plot": true,
                    "aggregation_config": {
                        "function": "last",
                        "short_name": "height"
                    }
                },
                {
                    "metric_id": "rsi_mod",
                    "display_name": "RSI",
                    "units": "m/s",
                    "plot": true,
                    "aggregation_config": {
                        "function": "last",
                        "short_name": "rsi"
                    }
                },
                {
                    "metric_id": "symmetry_score",
                    "display_name": "Symmetry Score",
                    "units": "%",
                    "plot": true,
                    "aggregation_config": {
                        "function": "last",
                        "short_name": "sym"
                    }
                }
            ],
            "squat_jump_v3": [
                {
                    "metric_id": "height",
                    "display_name": "Jump Height",
                    "units": "In",
                    "plot": true,
                    "aggregation_config": {
                        "function": "last",
                        "short_name": "height"
                    }
                },
                {
                    "metric_id": "rsi_mod",
                    "display_name": "RSI",
                    "units": "m/s",
                    "plot": true,
                    "aggregation_config": {
                        "function": "last",
                        "short_name": "rsi"
                    }
                },
                {
                    "metric_id": "symmetry_score",
                    "display_name": "Symmetry Score",
                    "units": "%",
                    "plot": true,
                    "aggregation_config": {
                        "function": "last",
                        "short_name": "sym"
                    }
                }
            ],
            "one_leg_counter_movement_jump_v3": [
                {
                    "metric_id": "height",
                    "display_name": "Jump Height",
                    "units": "In",
                    "plot": true,
                    "aggregation_config": {
                        "function": "last",
                        "short_name": "height"
                    }
                },
                {
                    "metric_id": "rsi_mod",
                    "display_name": "RSI",
                    "units": "m/s",
                    "plot": true,
                    "aggregation_config": {
                        "function": "last",
                        "short_name": "rsi"
                    }
                },
                {
                    "metric_id": "takeoff_duration",
                    "display_name": "Time to Takeoff",
                    "units": "ms",
                    "plot": true,
                    "aggregation_config": {
                        "function": "last",
                        "short_name": "takeoff"
                    }
                }
            ]
        }
    }
}