import { BrowserRouter } from "react-router-dom";
import Router from "./routes";
import ThemeProvider from './theme';
import "./index.css"
import './fonts/nasalization/nasalization-rg.ttf';
import './fonts/greycliff/GreycliffCF-Regular.ttf';
import './fonts/greycliff/GreycliffCF-Bold.ttf';
import './fonts/greycliff/GreycliffCF-DemiBold.ttf';
import './fonts/greycliff/GreycliffCF-Medium.ttf';

function App() {

  return (
    <BrowserRouter>
      <ThemeProvider>
        <Router />
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
