import React, { useEffect, useState } from 'react';
// @mui
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Stack, 
  Modal, 
  Divider, 
  Typography, 
  IconButton,
  TextField,
  Card,
  CardHeader,
  Switch,
} from '@mui/material/';

// @mui-icons
import { Close } from '@mui/icons-material';
// Apis
import { editOrganizationApiToken } from '../../api/orgs';
// Utils
import { calculateExpirationDate, formatDate } from '../../utils/formatFunctions';
import { toISOLocal } from '../../utils/formatFunctions';
import { commonModalStyle } from '../../utils/customStyles';
import { LoadingButton } from '@mui/lab';
// ----------------------------------------------------------------------

export default function EditApiToken ({
  orgId,
  openModal,
  currentToken,
  handleCloseModal
}){
  const [loadingStatus, setLoadingStatus] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [tokenData, setTokenData] = useState({})
  // calculatedDurationDate: formatDate(calculateExpirationDate(currentToken.dateCreated, currentToken.dateDuration))

  useEffect(()=> {
    setTokenData({
      dateDuration: currentToken.dateDuration,
      dateCreated: currentToken.dateCreated,
      expired: currentToken.expired,
      token: currentToken.token,
      id: currentToken.id
    })
  },[currentToken])

  const { dateDuration, expired, token, dateCreated, id } = tokenData

  const handleEditOrgApiToken = async() => {
    setLoadingStatus(true)
    const date = new Date()
    const formattedDate = toISOLocal(date)

    const apiToken = {
      id,
      expired,
      date_duration: dateDuration,
      date_created: dateCreated,
      token
    }

    console.log(apiToken)
    const response = await editOrganizationApiToken(orgId, apiToken)
    response.success ? handleCloseModal() : setErrorMessage('Something went wrong!')
    setLoadingStatus(false)
  }

  return (
    <Modal
      open={openModal}
      hideBackdrop={false}
      onClose={handleCloseModal}
    >
        <Card sx={commonModalStyle}>
            <CardHeader 
                title={"Edit Token"} 
                action={
                    <IconButton onClick={handleCloseModal}>
                        <Close/>
                    </IconButton>
                }
            />

            <Divider/>

            <Stack spacing={2} mt={2}>

              <Typography>
                Date Created: {dateCreated}
              </Typography>

              <Typography>
                Token: {token}
              </Typography>

              <TextField 
                label="Date to Expire" 
                fullWidth
                value={dateDuration}
                onChange={(e) => {
                  setTokenData({ ...tokenData, dateDuration: e.target.value });
                }}
              />

              <FormControl>
                <FormLabel>Expired</FormLabel>
                  <FormControlLabel control={
                      <Switch
                        checked={expired}
                        onChange={(e) => {
                          setTokenData({ ...tokenData, expired: e.target.checked });
                        }}
                      />
                    } 
                  />
              </FormControl>

              <Typography>{errorMessage}</Typography>

              <LoadingButton
                variant="contained"
                loading={loadingStatus}
                onClick={(e)=> handleEditOrgApiToken(e)}
                sx={{width: "50%", alignSelf: 'center'}}
              >
                Update API Token
              </LoadingButton>
            </Stack>
        </Card>
    </Modal>
  )
}