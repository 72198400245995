// @mui
import PropTypes from 'prop-types';
import { Stack, Typography, Table, TableCell, TableRow, TableContainer, TableBody, IconButton, Button } from '@mui/material';
import { Cancel, CheckCircle, WarningOutlined, ReportProblem } from '@mui/icons-material';
// Components
import { OrgListHead } from '../orgs';
// Utils
import { CustomTooltip } from '../../utils/customStyles';
import { formatDate, calculateExpiration, isValidDateFormat } from '../../utils/formatFunctions';
// ----------------------------------------------------------------------

OrgApiTokenList.propTypes = {
  list: PropTypes.array.isRequired,
};

export default function OrgApiTokenList ({ list, openModal, ...other  }) {

  const tableHead = [
    { id: 'token', label: 'Token', alignRight: false },
    { id: 'dateCreated', label: 'Date Created', alignRight: false },
    { id: 'dateDuration', label: 'Date Duration', alignRight: false },
    { id: 'expired', label: 'Expired', alignRight: false },
    { id: 'manuallyExpired', label: 'Manually Expired', alignRight: false },
    { id: 'edit', label: 'Edit Duration', alignRight: false },
  ];

  return (
    <TableContainer sx={{ minWidth: 800 }}>
      <Table>
        <OrgListHead
          // order={order}
          // orderBy={orderBy}
          headLabel={tableHead}
          // onRequestSort={handleRequestSort}
        />
        <TableBody>
          {list.map((row, index) => {
            return <OrgInfo info={row} index={index} openModal={openModal}/>
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

// ----------------------------------------------------------------------

// OrgInfo.propTypes = {
//   info: PropTypes.shape({
//     createdOn: PropTypes.string,
//     updatedOn: PropTypes.string,
//     orgId: PropTypes.string,
//     title: PropTypes.string,
//     userCount: PropTypes.number
//   }),
// };

function OrgInfo ({ info, openModal, index }) {
  const { dateCreated, dateDuration, expired, token } = info;
  const newToken ={
    ...info,
    id: index
  }

  const dateIsValid = isValidDateFormat(dateCreated)
  const remainingMembershipTime = calculateExpiration(dateCreated, dateDuration);

  return (
    <TableRow hover tabIndex={-1}>

      <TableCell component="th" scope="row">
        <Stack direction={"row"}>
          <Typography>{token}</Typography>

          { !dateIsValid && 
            <CustomTooltip title={"Invalid Date Format"} placement="top">
              <WarningOutlined sx={{color: 'warning.dark'}}/>
            </CustomTooltip>
          }
        </Stack>
      </TableCell>

      <TableCell>
        <Typography>{formatDate(dateCreated)}</Typography>
      </TableCell>

      <TableCell>
        <Typography>{dateDuration}</Typography>
      </TableCell>

      <TableCell>
        <Stack direction="row" spacing={1}>
          {remainingMembershipTime.differenceInDays < 0  ? <Cancel sx={{color: "error.main"}}/> : remainingMembershipTime.differenceInDays  < 10 ? <ReportProblem sx={{color: "warning.main"}}/> : <CheckCircle sx={{color: "success.main"}}/>}
          <Typography>
          {remainingMembershipTime.differenceInDays  < 0  ? `Expired` : remainingMembershipTime.differenceInDays  < 10 ? `${remainingMembershipTime.differenceInDays } days left` : `${remainingMembershipTime.differenceInDays } days`}
          </Typography>
        </Stack>
        <Typography>{remainingMembershipTime.differenceInDays <= 0 ? "Expired on": "Expires on"}: {formatDate(remainingMembershipTime.expirationDate)}</Typography>
      </TableCell>

      <TableCell>
        <Typography>{expired ? "Yes" : "No"}</Typography>
      </TableCell>

      <TableCell>
        <Button variant="outlined" onClick={() => openModal(newToken)}  color="info">Edit</Button>
      </TableCell>

    </TableRow>
  );
}
