import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// @mui
import { Grid, Container, Typography, Stack, Divider} from '@mui/material';
// components
// sections
// API Calls
import { findSessionById } from "../api/sessions";
// ----------------------------------------------------------------------

export default function User () {
  const [sessionData, setSessionData] = useState(null)
  const location = useLocation();
  const sessionId = location.state.sessionId

  useEffect(()=> {

    const fetchSessionData = async(id) => {
        try {
            const data = await findSessionById(id)
            console.log(data)
            setSessionData(data.data[0])
        } catch(err) {
            console.log(err)
        }
    }

    fetchSessionData(sessionId)
  }, [sessionId])

  if(sessionData){
      return (
        <>
          <Container maxWidth="xl">
  
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Stack flexDirection={"row"}>
                            <Typography variant="h3" sx={{ mb: 5, mr: 2 }}>Session #{sessionData.session_number}</Typography>
                        </Stack>
                    <Divider/>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                        <Typography>
                            Session Id: {sessionData.session_id}
                        </Typography>
                        <Typography>
                            Valid: {sessionData.is_valid ? "Yes": "No"}
                        </Typography>
                    </Grid>
                </Grid>
          </Container>
        </>
    );
  }
}