import { filter } from 'lodash';

export function stringAvatar(name) {
    if(name.split(' ').length >= 2){
        return {
          children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    } else {
        return {
            children: `${name.split(' ')[0][0]}`
        }
    }
  }

export function formatDate(date) {

  let slicedDate = date.slice(0,10)

  const [year, month, day] = slicedDate.split('-');

  return `${month}/${day}/${year}`;

};

export function getTimezoneOffsetString() {
    const now = new Date();
    const offsetMinutes = now.getTimezoneOffset();
  
    const offsetHours = Math.abs(Math.floor(offsetMinutes / 60));
    const offsetMinutesRemainder = Math.abs(offsetMinutes % 60);

    const offsetString = (offsetMinutes >= 0 ? '-' : '+') +
      String(offsetHours).padStart(2, '0') + ':' +
      String(offsetMinutesRemainder).padStart(2, '0');
  
    return offsetString;
}

// ISO Time
export function toISOLocal(d) {
  var z  = n =>  ('0' + n).slice(-2);
  var zz = n => ('00' + n).slice(-3);
  var off = d.getTimezoneOffset();
  var sign = off > 0? '-' : '+';
  off = Math.abs(off);

  return d.getFullYear() + '-'
         + z(d.getMonth()+1) + '-' +
         z(d.getDate()) + 'T' +
         z(d.getHours()) + ':'  + 
         z(d.getMinutes()) + ':' +
         z(d.getSeconds()) + '.' +
         zz(d.getMilliseconds()) +
         sign + z(off/60|0) + ':' + z(off%60); 
}


export function convertToNonMilitaryTime(militaryTime) {
  // Split the military time string into hours and minutes
  var timeArray = militaryTime.split(':');
  var hours = parseInt(timeArray[0]);
  var minutes = parseInt(timeArray[1]);

  // Convert hours to non-military format
  var nonMilitaryHours = (hours % 12) || 12; // If hours is 0, convert to 12
  var period = hours < 12 ? 'AM' : 'PM';

  // Format the time
  var nonMilitaryTime = nonMilitaryHours + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + period;

  return nonMilitaryTime;
}


export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_org) => _org.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
export function calculateExpiration(originalDateStr, daysAccess) {
  const originalDate = new Date(originalDateStr.slice(0,10));
  const expirationDate = new Date(originalDate.getTime() + daysAccess * 24 * 60 * 60 * 1000);
  const today = new Date();
  const differenceInTime = expirationDate.getTime() - today.getTime();
  const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
  const expirationDateString = expirationDate.toISOString().slice(0, 10);
  return { differenceInDays: differenceInDays, expirationDate: expirationDateString };
}


export function isInteger(value) {
  if (value === "" || value === "-") {
    return true; // Empty or just a negative symbol, consider it valid
  }
  return /^-?\d+$/.test(value) && Number.isInteger(parseInt(value, 10));
}

export function isValidDateFormat(dateString) {
  const pattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{2,3}-\d{2}:\d{2}$/;
  return pattern.test(dateString);
}

export function calculateExpirationDate(originalDateStr, daysAccess) {
  const originalDate = new Date(originalDateStr.slice(0,10));
  const expirationDate = new Date(originalDate.getTime() + daysAccess * 24 * 60 * 60 * 1000);
  return expirationDate.toISOString().slice(0, 10);
}