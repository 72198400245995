import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// @mui
import { Grid, Container, Typography, Stack, Divider} from '@mui/material';
// components
// sections
import { UserInfoList, UserDeviceList } from "../sections/user";
// API Calls
import { findUserById } from "../api/users";
// ----------------------------------------------------------------------

export default function User () {
  const [userData, setUserData] = useState(null)
  const location = useLocation();
  const userId = location.state.userId
  const orgId = location.state.orgId

  useEffect(()=> {

    const fetchUserData = async(user, org) => {
        try {
            const data = await findUserById(user, org)
            console.log(data)
            setUserData(data.data[0])
        } catch(err) {
            console.log(err)
        }
    }

    fetchUserData(userId, orgId)
  }, [userId, orgId])

  if(userData){
      return (
        <>
          <Container maxWidth="xl">
  
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Stack flexDirection={"row"}>
                            <Typography variant="h3" sx={{ mb: 5, mr: 2 }}> {userData.name}</Typography>
                        </Stack>
                    <Divider/>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <UserInfoList
                            title="User Information"
                            list={
                                [{
                                    createdOn: userData.date_created.slice(0,10),
                                    updatedOn: userData.date_updated.slice(0,10),
                                    orgId: userData.organization_id,
                                    userId: userData.user_id,
                                    userCount: userData.user_count,
                                    type: userData.type,
                                }]
                              }
                        />
                    </Grid>


                    <Grid item xs={12} sm={6} md={4}>
                        <UserDeviceList
                            title="User Devices"
                            list={userData.device_list}
                        />
                    </Grid>

                </Grid>
          </Container>
        </>
    );
  }
}