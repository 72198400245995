// @mui
import PropTypes from 'prop-types';
import { Typography, Table, TableCell, TableRow, TableContainer, TableBody, Toolbar, Button, Chip } from '@mui/material';
import { OpenInNew } from '@mui/icons-material';
// Components
import { OrgListHead } from '../orgs';
// Utils
import { productIconMap } from '../../utils/mapFunctions';
import { CustomTooltip } from '../../utils/customStyles';
// ----------------------------------------------------------------------

OrgMetricList.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
};

export default function OrgMetricList ({ title, subheader, list, displayJson, ...other }) {

  const tableHead = [
    { id: 'activity', label: 'Activity', alignRight: false },
    { id: 'product', label: 'Product', alignRight: false },
    { id: 'metric', label: 'Metric', alignRight: false },
  ];

  const handleShowJson = () => {
    const jsonContent = JSON.stringify(displayJson, null, 2);
    const newWindow = window.open('', '_blank');
    newWindow.document.write(`<pre>${jsonContent}</pre>`);
  };

  return (
    <TableContainer sx={{ minWidth: 800 }}>
      <Toolbar sx={{justifyContent: "space-between"}}>
        <Button onClick={handleShowJson} variant="outlined"  color="info" startIcon={<OpenInNew/>}>
          View Display Json
        </Button>
      </Toolbar>
      <Table>
        <OrgListHead
          // order={order}
          // orderBy={orderBy}
          headLabel={tableHead}
          // onRequestSort={handleRequestSort}
        />
        <TableBody>
          {list.map((row) => {
            return <OrgMetric metric={row}/>
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

// ----------------------------------------------------------------------

OrgMetric.propTypes = {
  metric: PropTypes.shape({
    display_name: PropTypes.string,
    product_id: PropTypes.string,
    title: PropTypes.string,
  }),
};

function OrgMetric ({ metric }) {
  const { display_name, products, metric_list, activity_id } = metric;

  const findKeyByActivityId = (product_ids, activityId) => {
    for (const key in product_ids) {
        if (product_ids[key].includes(activityId)) {
            return key;
        }
    }
    return null; 
  };

  const product = findKeyByActivityId(products, activity_id)

  return (
    <TableRow hover tabIndex={-1}>
      <TableCell component="th" scope="row">
        <CustomTooltip title={activity_id} placement="top-start" arrow>
          <Typography color="inherit" fontWeight={700} underline="hover" noWrap>
            {display_name}
          </Typography>
        </CustomTooltip>
      </TableCell>

      <TableCell>
        <CustomTooltip title={product} placement="top-start" arrow>
          {productIconMap[product]}
        </CustomTooltip>
      </TableCell>

      <TableCell>
         <Chip label="metric"/>
      </TableCell>
    </TableRow>
  );
}
