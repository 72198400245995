export function validateUserObject(userObj) {
    const missingKeys = [];
    if(!userObj){
      missingKeys.push("User Data is Missing!");
    }
    if (!userObj.user_metadata) {
        missingKeys.push("No Meta data");
    } else {
        if (!userObj.user_metadata.dob) missingKeys.push("Date Of Birth");
        if (!userObj.user_metadata.height) missingKeys.push("Height");
        else {
            if (!userObj.user_metadata.height.value) missingKeys.push("Height Value");
            if (!userObj.user_metadata.height.unit) missingKeys.push("Height Unit");
        }
        if (!userObj.user_metadata.weight) missingKeys.push("Weight");
        else {
            if (!userObj.user_metadata.weight.value) missingKeys.push("Weight Value");
            if (!userObj.user_metadata.weight.unit) missingKeys.push("Weight Unit");
        }
    }
    
    return missingKeys;
  }


export function validateOrganizationObject(obj) {
    const expectedKeys = [
        "version",
        "date_duration",
        "organization_id",
        "admin_count",
        "date_updated",
        "user_count",
        "s3_logo_url",
        "download_metadata",
        "date_created",
        "name",
        "token_api",
        "token_sdk",
        "admin_max",
        "pk_id",
        "download_raw",
        "expired",
        "user_max",
        "notes",
        "org_type",
        "timezone",
        "sk_id",
        "download_analyzed",
    ];

    const missingKeys = expectedKeys.filter(key => !(key in obj));
    if (missingKeys.length > 0) {
        return { valid: false, message: `Missing keys: ${missingKeys.join(", ")}` };
    }

    const expectedDataTypes = {
        "version": "string",
        "date_duration": "number",
        "organization_id": "string",
        "admin_count": "number",
        "date_updated": "string",
        "user_count": "number",
        "s3_logo_url": "string",
        "download_metadata": "boolean",
        "date_created": "string",
        "name": "string",
        "token_api": "array",
        "token_sdk": "array",
        "admin_max": "number",
        "pk_id": "string",
        "download_raw": "boolean",
        "expired": "boolean",
        "user_max": "number",
        "notes": "string",
        "org_type": "string",
        "timezone": "string",
        "sk_id": "string",
        "download_analyzed": "boolean",
    };
    
    const unexpectedKeys = Object.keys(obj).filter(key => !expectedKeys.includes(key));
    if (unexpectedKeys.length > 0) {
        return { valid: false, message: `Unexpected key: ${unexpectedKeys.join(", ")}` };
    }
    const invalidTypes = Object.keys(obj).filter((key) => {
        const dataType = Array.isArray(obj[key]) ? "array" : typeof obj[key];
        return dataType !== expectedDataTypes[key];
    }
    );
    if (invalidTypes.length > 0) {
        return { valid: false, message: `Invalid data type for: ${invalidTypes.join(", ")}` };
    }

    return { valid: true, message: "Object validation successful" };
}