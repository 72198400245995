import  React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// @mui
import { Grid, Box, Container, Typography, Stack, Avatar, Divider, Button, Tooltip, Accordion, AccordionSummary, AccordionDetails, Snackbar, Alert, AccordionActions, IconButton} from '@mui/material';
// icons
import { AssignmentIndOutlined, Add, ContentCopy, InfoOutlined, WarningOutlined, ExpandMore, DataObject, DataObjectOutlined } from '@mui/icons-material';
// Sections
import { 
    OrgUserList,
    OrgMetricList,
    EditOrg,
    OrgApiTokenList,
    CreateOrgApiToken,
    EditApiToken,
    InviteEmail,
} from '../sections/org'
import json from '../assets/json.png'
// API Calls
import { getOrgInfoAndUsers } from "../api/orgs";
// Utils
import { formatDate, calculateExpiration, isValidDateFormat } from "../utils/formatFunctions";
import { handleCopyClick } from "../utils/copyFunction";
import { CustomTooltip } from "../utils/customStyles";
// ----------------------------------------------------------------------

export default function Org () {
  const [orgData, setOrgData] = useState(null)
  const [orgUsers, setOrgUsers] = useState(null)
  const [orgAdmins, setOrgAdmins] = useState(null)
  const [openEditModal, setOpenEditModal] = useState(false)
  const [openApiModal, setOpenApiModal] = useState(false)
  const [openEditApiModal, setOpenEditApiModal] = useState(false)
  const [openInviteModal, setOpenInviteModal] = useState(false)
  const [activityData, setActivityData] = useState([])
  const [userType, setUserType] = useState("")
  const [currentToken, setCurrentToken] = useState("")
  const location = useLocation();
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    vertical: 'bottom',
    horizontal: 'center',
    text: ""
  });
  const { vertical, horizontal, open, text } = snackbarState;
  const orgId = location.state.orgId
  const userData = location.state.userData
  const orgSessions = location.state.orgSessions
  const isAdvanced = location.state.isAdvanced

  useEffect(()=> {

    const fetchOrgData = async(id) => {
        try {
            const orgData = await getOrgInfoAndUsers(id)
            const users = orgData.users
            setOrgData(orgData.org[0])
            setActivityData(orgData.org[1])
            setOrgUsers(users)
        } catch(err) {
            console.log(err)
        }
    }

    fetchOrgData(orgId)

  }, [orgId, orgAdmins])

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
  
    setSnackbarState({ ...snackbarState, open: false });
  };

  const handleShowJson = () => {
    const jsonContent = JSON.stringify(orgData, null, 2);
    const newWindow = window.open('', '_blank');
    newWindow.document.write(`<pre>${jsonContent}</pre>`);
  };

  const handleOpenInviteModal = (type) => {
    setOpenInviteModal(true)
    setUserType(type)
  }

  const openEditAPIModal = (token) => {
    setOpenEditApiModal(true);
    setCurrentToken(token)
  };

  if (orgData && orgUsers && activityData) {
    const remainingMembershipTime = calculateExpiration(orgData.date_created, orgData.date_duration);
    const dateIsValid = isValidDateFormat(orgData.date_created)
    const users = orgUsers.filter(user => user.type === "user")
    const admins = orgUsers.filter(user => user.type === "admin")

    return (
        <>
          <Container maxWidth="xl">
            <Grid container spacing={3}>

                <Grid item xs={12}>
                    <Stack 
                        direction="row" 
                        justifyContent="space-between" 
                        alignItems={"center"} 
                        mb={2}
                    >
                        <Stack direction="row" spacing={1} alignItems={"center"}>
                            <Avatar alt={orgData.name} src={orgData.s3_logo_url}/>
                            
                            <Typography variant="h3">{orgData.name}</Typography>

                            {orgData.notes && 
                                <CustomTooltip title={orgData.notes} placement="top">
                                    <InfoOutlined/>
                                </CustomTooltip>
                            }

                            {dateIsValid === false &&                  
                                <CustomTooltip title={"Invalid Date Format"} placement="top">
                                    <WarningOutlined sx={{color: 'error.main'}}/>
                                </CustomTooltip>
                            }

                            <IconButton>
                               <Box 
                                    onClick={handleShowJson}
                                    component="img"
                                    sx={{ width: 30, height: 30 }}
                                    src={json}
                                />
                            </IconButton>

                        </Stack>

                        <Button variant="contained" sx={{maxHeight: "2rem"}} onClick={()=> setOpenEditModal(true)}>
                            Edit Organization
                        </Button>

                        <EditOrg 
                            openModal={openEditModal} 
                            isAdvanced={isAdvanced}
                            handleCloseModal={()=> setOpenEditModal(false)} 
                            orgId={orgId} 
                            orgData={orgData}
                            snackbarState={snackbarState} 
                            setSnackbarState={setSnackbarState}
                        />
                    </Stack>

                    <Stack         
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={2}
                        mb={2}
                    >
                        <Stack direction="row" spacing={1}>
                            <Tooltip arrow title={
                                <Stack direction={"row"}>
                                    <Typography>{orgId}</Typography>
                                    <ContentCopy onClick={()=> handleCopyClick(orgId)}/>
                                </Stack>
                            }>
                                <AssignmentIndOutlined />
                            </Tooltip>
                            <Typography fontWeight={700}>Org ID</Typography>
                        </Stack>

                        <>
                            <Typography fontWeight={700}>Created On:</Typography>
                            <Typography>{formatDate(orgData.date_created.slice(0,10))}</Typography>
                        </>

                        <>
                            <Typography fontWeight={700}>User Count:</Typography>
                            <Typography>{userData.length}</Typography>
                        </>


                        <>
                            <Typography fontWeight={700}>{remainingMembershipTime.differenceInDays <= 0 ? "Expired on:": "Expires on:"}</Typography>
                            <Typography>{formatDate(remainingMembershipTime.expirationDate)}</Typography>
                        </>

                        <>
                            <Typography fontWeight={700}>User Max:</Typography>
                            <Typography>{orgData.user_max}</Typography>
                        </>

                        <>
                            <Typography fontWeight={700}>Admin Max:</Typography>
                            <Typography>{orgData.admin_max}</Typography>
                        </>

                        { orgData.type && 
                            <>
                                <Typography fontWeight={700}>Org Type:</Typography>
                                <Typography>{orgData.type}</Typography>
                            </>
                        }
                    </Stack>

                    <Divider/>
                </Grid>
            
                <Grid item xs={12}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore/>}>
                            <Typography variant="h5">
                                API List
                            </Typography>
                        </AccordionSummary>
    
                        <AccordionDetails>
                            <OrgApiTokenList
                                openModal={openEditAPIModal} 
                                list={
                                    orgData.token_api?.map((token) => ({
                                        expired: token.expired,
                                        dateCreated: token.date_created,
                                        token: token.token,
                                        dateDuration: token.date_duration
                                    })) ?? []
                                }
                            />
                        </AccordionDetails>
                        <AccordionActions>
                            <Button
                                variant="outlined" 
                                color="info" 
                                startIcon={<Add sx={{color: "primary.main"}}/>}
                                onClick={()=> setOpenApiModal(true)} 
                            >
                                Add API Token
                            </Button>
                        </AccordionActions>
                    </Accordion>

                    <CreateOrgApiToken 
                        openModal={openApiModal} 
                        handleCloseModal={()=> setOpenApiModal(false)} 
                        orgName={orgData.name} 
                        orgId={orgId}
                    />

                    <EditApiToken 
                        orgId={orgId}
                        openModal={openEditApiModal} 
                        currentToken={currentToken}
                        handleCloseModal={()=> setOpenEditApiModal(false)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore/>}>
                            <Typography variant="h5">Metric List</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <OrgMetricList
                                displayJson={activityData}
                                list={activityData.activity_list.map((metric, index) => ({
                                    display_name: metric.display_name,
                                    products: activityData.product_activity_mapping,
                                    metric_list: activityData.metric_list,
                                    activity_id: metric.activity_id,
                                }))}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                <Grid item xs={12}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore/>}>
                            <Typography variant="h5">Admin List</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <OrgUserList
                                title="Admin List"
                                setOrgAdmins={setOrgAdmins}
                                orgSessions={orgSessions}
                                action={
                                    <Button 
                                        variant="outlined" 
                                        color="info" 
                                        disableRipple 
                                        disableElevation
                                        startIcon={<Add sx={{color: "primary.main"}}/>}
                                        onClick={()=>handleOpenInviteModal("Admin")} 
                                    >
                                        Add Admin
                                    </Button>
                                }
                                list={admins.map((admin, index) => ({
                                        id: index,
                                        name: admin.name,
                                        userId: admin.user_id,
                                        orgId: admin.organization_id,
                                        adminNotes: admin.admin_settings.notes,
                                        dateCreated: admin.date_created,
                                        email: admin.email,
                                        totalSessions: admin.session_history_metadata.total_sessions,
                                        userType: admin.type,
                                        isActive: admin.is_active,
                                    }))
                                }
                            />
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                <Grid item xs={12}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore/>}>
                            <Typography variant="h5">User List</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <OrgUserList
                                title="User List"
                                setOrgAdmins={setOrgAdmins}
                                orgSessions={orgSessions}
                                action={
                                    <Button 
                                        variant="outlined" 
                                        color="info" 
                                        disableRipple 
                                        disableElevation
                                        startIcon={<Add sx={{color: "primary.main"}}/>}
                                        onClick={()=>handleOpenInviteModal("User")} 
                                    >
                                        Add User
                                    </Button>
                                }
                                list={users.map((user, index) => ({
                                    id: index,
                                    name: user.name,
                                    userId: user.user_id,
                                    orgId: user.organization_id,
                                    adminNotes: user.admin_settings.notes,
                                    dateCreated: user.date_created,
                                    email: user.email,
                                    totalSessions: user.session_history_metadata.total_sessions,
                                    userType: user.type,
                                    isActive: user.is_active,
                                }))}
                            />
                        </AccordionDetails>
                    </Accordion>

                    <InviteEmail 
                        openModal={openInviteModal} 
                        userType={userType}
                        handleCloseModal={()=> setOpenInviteModal(false)} 
                        orgId={orgId} 
                        snackbarState={snackbarState} 
                        setSnackbarState={setSnackbarState}
                    />

                </Grid>

                <Snackbar 
                    open={open} 
                    anchorOrigin={{ vertical, horizontal }}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                    key={vertical + horizontal}
                >
                    <Alert
                        onClose={handleCloseSnackbar}
                        severity="success"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        {text}
                    </Alert>
                </Snackbar>
            </Grid>
          </Container>
        </>
    );
  }
}